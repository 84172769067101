/* App.css */

/* Global Styles */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.signature-canvas {
  touch-action: none;
  -ms-touch-action: none;
}

/* Modal and Scrim */
.scrim {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Dark semi-transparent background */
  z-index: 999; /* Ensure it appears above the page content */
  display: none; /* Initially hidden */
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  z-index: 1000; /* Ensure the modal appears above the scrim */
  display: none; /* Initially hidden */
}

.modal-content {
  background-color: white;
  color: black;
  padding: 20px;
}

.modal-header,
.modal-body,
.modal-footer {
  padding: 10px;
}

/* Button Styles */
button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* Make sure the modal and scrim show when modalVisible is true */
.modal.show, .scrim.show {
  display: block;
}

/* Calendar Styles */
.calendar-disabled {
  pointer-events: none;
  opacity: 0.5; /* Optional: make the calendar look visually inactive */
}

/* Customize the calendar grid */
.fc-view {
  background-color: #f9f9f9;
}

/* Customize the day cells */
.fc-day {
  padding: 20px;
  font-size: 16px;
  border: 1px solid #ddd;
}

/* Style the current day */
.fc-day.fc-day-today {
  background-color: #e1f5fe;
  font-weight: bold;
}

/* Style the event title */
.fc-event-title {
  font-size: 14px;
  color: #ffffff;
  padding: 5px;
}

/* Customize event background color */
.fc-event {
  background-color: #8017c6; /* Purple */
  color: white;
  border-radius: 5px;
  padding: 1px;
}

/* Style event on hover */
.fc-event:hover {
  background-color: #388e3c;
  cursor: pointer;
}

/* Style the modal background */
.fc-event-modal {
  background-color: rgba(0, 0, 0, 0.6);
}

/* Add some margin to the event titles */
.fc-day-number {
  margin-top: 10px;
}

/* Container for search and filters */
.search-filter-container {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-bottom: 20px;
}

/* Search bar and filters in a row */
.search-filter-container > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.search-filter-container input {
  padding: 8px;
  width: 260px;
}

/* Filters section */
.filters {
  display: flex;
  flex-direction: row;
  gap: 10px;  /* Adds space between the elements */
  align-items: center;  /* Ensures the elements are vertically aligned */
}

/* Container for the dropdown */
.dropdown {
  position: relative;  /* Make sure the dropdown is positioned below the button */
  margin-bottom: 10px;  /* Add spacing below the dropdown */
}

.filters > .dropdown {
  display: flex; /* Ensure dropdown behaves correctly in the row */
  flex-direction: column; /* Keep the dropdown content in a vertical stack */
}

/* Dropdown button style */
.dropdown-btn {
  background-color: #73178a;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

/* Hover effect on button */
.dropdown-btn:hover {
  background-color: #9b4eca;  /* Darker blue when hovered */
}

/* Show the dropdown content when the button is clicked */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border: 1px solid #ddd;
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
}

/* Display the dropdown when the button is clicked */
.dropdown.open .dropdown-content {
  display: block;
}

/* Styling each label with checkbox and text */
.dropdown-content label {
  display: flex;
  align-items: center;  /* This ensures the checkbox and text are aligned vertically */
  padding: 5px 0;
  cursor: pointer;
}

/* Hover effects for items */
.dropdown-content label:hover {
  background-color: #ddd;
}

/* Style the checkboxes */
.dropdown-content input[type="checkbox"] {
  margin-right: 4px;  /* Space between checkbox and text */
  accent-color: #007bff;  /* Change color of checkbox to match button */
  cursor: pointer;  /* Pointer cursor on hover */
}

/* Dropdown button and reset button styles */
.filters > button {
  display: inline-block; /* Make the reset button appear inline */
}

button {
  padding: 10px;
  background-color: blue;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: darkblue;
}

/* Calendar container */
.calendar-container {
  margin-top: 20px;
  padding: 20px;
  width: 100%;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}

.date-picker-custom {
  width: 100%;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  font-size: 16px;
}

.date-picker-custom:focus {
  border-color: #1976d2;
  outline: none;
}